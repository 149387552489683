/*
Author       : Revolve
Template Name: Minimal Blog Template
Author URI : https://themefisher.com/
Description: Revolve,blog,minimal,multipage,contact 
Version      : 1.0
*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]

1. BASE

  1.1. Typography
  1.2. Common Styles

2. HEADER

  2.1. Navigation
  2.2. Site Search
  2.3. Top Navigation

3. TRANDING NEWS

4. POST STYLES

  4.1. Post Title
  4.2. Post Meta
  4.3. Post Overlay
  4.4. Post Block
  4.5. Post List
  4.6. Post Slider
  4.7. Side Post Slider
  4.8. Pagination

5. POST SINGLE STYLES

  5.1. Single Post Title
  5.2. Feature Image
  5.3. Post Ads
  5.4. Post Tag
  5.5. Post Share Widget
  5.6. Post Navigation
  5.7. Author Box
  5.8. Comment Styles

6. FEATURE POST STYLES

7. POST CATEGORY STYLES

8. SIDEBAR

  8.1. Social Pages
  8.2. Sidebar Widget Common Styles
  8.3. Sidebar Nav
  8.4. Newsletter
  8.5. Tags
  8.6. Review Post 
  8.7. Top Authors

9. VIDEO GALLERY

10. CONTACT

11. FOOTER

12. RESPONSIVE STYLES

-------------------------------------------------------------------*/
/*=== MEDIA QUERY ===*/
/* 1.1. Typography
================================================== */
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Lato:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Lora:400,700|Open+Sans|Unna:400,700&display=swap");
body, p {
  font-family: "Open Sans", sans-serif;
  line-height: 26px;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  color: #666;
  font-weight: 400;
}

html {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: #1c1c1c;
  font-weight: 600;
  font-family: "Unna", serif;
}

h1, .h1 {
  font-size: 38px;
  line-height: 48px;
}

h2, .h2 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

h3, .h3 {
  font-size: 24px;
  font-weight: 700;
}

h4, .h4 {
  font-size: 20px;
  line-height: 28px;
}

h5, .h5 {
  font-size: 16px;
  line-height: 26px;
}

h6, .h6 {
  font-size: 14px;
  line-height: 22px;
}

.font-extra {
  font-family: "Lora", serif;
}

/* 1.2. Common styles
================================================== */
body {
  background: #fff;
  color: #575769;
}

html, body {
  height: 100%;
}

a {
  transition: all 0.3s ease-in-out;
  color: #000;
}
a:link, a:visited {
  text-decoration: none;
}
a:hover {
  color: #ce8460;
}
a.read-more {
  margin-top: 10px;
  display: inline-block;
  color: #ce8460;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
}
a.read-more:hover {
  color: #000;
}

.section-padding {
  padding: 70px 0;
  position: relative;
}

.section-sm {
  padding: 30px 0;
}

a:focus {
  outline: 0;
}

/* Input form */
.form-control {
  box-shadow: none;
  border: 1px solid transparent;
  padding: 12px 20px;
  background: #f0f1f4;
  font-size: 14px;
  border-radius: 2px;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #ce8460;
}

blockquote {
  position: relative;
  padding: 0px 50px 30px 40px;
  margin: 50px 0 40px;
  font-size: 20px;
  line-height: 34px;
  font-family: "Lora", serif;
  font-weight: 400;
  font-style: italic;
  text-align: center;
}

/* Button */
.btn {
  border-radius: 0px;
  padding: 10px 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  transition: 350ms;
  letter-spacing: 1px;
  font-size: 13px;
  border: 1px solid transparent;
}

.btn-primary {
  background: #ce8460;
}
.btn-primary:hover {
  background: #000;
  color: #fff;
  border-color: #000;
}

.btn-dark {
  background: #292931;
}

.btn-dark:hover, .btn-white.btn-primary:hover {
  background: #272d33;
  color: #fff;
}

.btn-grey {
  background: #ce8460;
  border-color: #ce8460;
  color: #fff;
}
.btn-grey:hover {
  background: transparent;
  border-color: #ce8460;
  color: #000;
}

.slick-slide {
  outline: 0;
}

.letter-spacing {
  letter-spacing: 3px;
}

.font-sm {
  font-size: 12px !important;
}

.text-color {
  color: #ce8460;
}

.bg-grey {
  background: #f8efea;
}

.gray-bg {
  background: #f5f5f5;
}

.padding-bottom {
  padding-bottom: 120px;
}

.padding-top {
  padding-top: 120px;
}

.navigation .menu {
  padding: 0px;
}
.navigation .menu li {
  text-align: left;
  padding: 0px 10px;
}
.navigation .menu li a {
  color: #000;
  display: inline-block;
  line-height: 26px;
  letter-spacing: 1px;
}
.navigation .menu .dropdown-toggle::after {
  display: none;
}
.navigation .menu .dropdown {
  position: relative;
}
.navigation .menu .dropdown-menu {
  margin-left: 0px;
  transition: all 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  -webkit-transition: all 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  -o-transition: all 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  -ms-transition: all 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  width: 250px;
  height: auto;
  padding: 0px;
  border-radius: 0px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.03);
  color: #fff;
  background-color: #000;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}
.navigation .menu .dropdown-menu a {
  font-weight: 400;
  color: #fff;
  background: #000;
  padding: 15px;
  min-width: 210px;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 12px 20px;
}
.navigation .menu .dropdown-menu a:hover {
  background: #111;
  color: #fff;
  padding-left: 20px;
}
.navigation .menu .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  display: block;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}

.dropdown-toggle::after {
  display: none;
}

.navbar-nav .nav-link {
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 25px 0px;
}
.navbar-nav .nav-link:hover {
  color: #ce8460 !important;
}

.header-socials a {
  font-size: 12px;
  padding: 0px 5px;
}

.bg-dark {
  background: #111111 !important;
}

.navigation-dark .navigation .navbar-nav .nav-link {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.navigation-dark .header-socials-2 a {
  color: rgba(255, 255, 255, 0.7);
}

.navigation.menu-white .navbar-nav .nav-link {
  color: #fff;
}

.header-socials-2 a {
  padding: 0px 5px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.search a {
  color: #000;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

/*-------------------------------
overlay search
-------------------------------*/
.search-wrap {
  position: relative;
  z-index: 1200;
  display: inline-block;
  float: right;
  top: 20px;
  right: 16px;
}

.toggle-wrap {
  position: relative;
}

.search_toggle {
  cursor: pointer;
}

.toggle-wrap.active {
  position: relative;
  top: 0%;
  right: 0%;
  cursor: pointer;
  z-index: 10000;
  transition: opacity 0.25s ease;
}

.toggle-wrap.active img {
  display: none;
}

.toggle-wrap img.search-close {
  display: none;
}

.toggle-wrap.active img.search-close {
  display: block;
}

.overlay {
  position: fixed;
  background: #1f1f1f;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.4s;
  overflow: hidden;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.overlay.open .col-md-3 {
  opacity: 1;
  -webkit-transform: translate3d(-100px, 0, 0);
  transform: translateY(-100px);
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}

.overlay.open .col-md-3:first-child {
  transition-delay: 0.4s;
}

.overlay.open .col-md-3:nth-child(2) {
  transition-delay: 0.45s;
}

.overlay.open .col-md-3:nth-child(3) {
  transition-delay: 0.5s;
}

.overlay.open .col-md-3:nth-child(4) {
  transition-delay: 0.55s;
}

.overlay .col-md-3 {
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.overlay .search-form {
  position: relative;
  height: 300px;
  padding-top: 120px;
  background: #222222;
}

.overlay .search-form input {
  background: transparent;
  border: none;
  font-size: 56px;
  height: 60px;
  color: #fff;
}

.overlay .search-form input:focus {
  box-shadow: none;
}

.overlay nav,
.overlay .search-blog-post {
  position: relative;
  height: 60%;
  top: 55%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.5rem;
}

.overlay .search-blog-post .post-header h3 {
  font-size: 16px;
  line-height: 26px;
}

.overlay .search-blog-post .post-header h3 a {
  color: #fff;
}

.overlay .search-blog-post .post-header h3 a:hover {
  color: #cba866;
}

.overlay .search-blog-post .post-meta {
  font-size: 14px;
}

.search-blog-title {
  position: relative;
  top: 3.5rem;
}

@media (max-width: 1366px) {
  .toggle-wrap.active {
    right: 10%;
  }
}
@media (max-width: 1024px) {
  .overlay .search-form {
    height: 200px;
    padding-top: 70px;
  }
  .toggle-wrap.active {
    top: 8%;
  }
}
@media (max-width: 768px) {
  .overlay nav, .overlay .search-blog-post {
    top: 50%;
  }
  .toggle-wrap.active {
    top: 7%;
  }
}
@media (max-width: 767px) {
  .overlay nav, .overlay .search-blog-post, .search-blog-title {
    display: none;
  }
  .overlay .search-form {
    height: 100%;
    padding-top: 50%;
  }
  .toggle-wrap.active {
    top: 15%;
    right: -10px;
  }
  .overlay .search-form input {
    font-size: 24px;
  }
  .toggle-wrap.active img.search-close {
    width: 70%;
  }
}
.sidebar {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.content {
  width: 85%;
  margin: 0 auto;
  padding-left: 170px;
}

.sidebar-sticky {
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #fff;
  padding-left: 30px;
  z-index: 99999;
  height: 100%;
  width: 14%;
  border-right: 2px solid #eee;
}

.main-menu a {
  color: #000;
  display: inline-block;
  line-height: 26px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding-left: 0px;
  letter-spacing: 1px;
}
.main-menu .dropdown-menu {
  margin-left: 0px;
  transition: all 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  -webkit-transition: all 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  -o-transition: all 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  -ms-transition: all 500ms cubic-bezier(0.43, 0.26, 0.11, 0.99);
  width: 250px;
  height: auto;
  padding: 0px;
  border-radius: 0px;
  border: 2px solid rgba(0, 0, 0, 0.03);
  color: #fff;
  background-color: #000;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}
.main-menu .dropdown-menu a {
  font-weight: 400;
  color: #fff;
  background: #000;
  padding: 15px;
  min-width: 210px;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 12px 20px;
}
.main-menu .dropdown-menu a:hover {
  background: #111;
  color: #fff;
  padding-left: 20px;
}

.main-menu {
  padding: 70px 0 150px;
}

.header-social-wrapper {
  position: absolute;
  bottom: 20px;
  display: inline-block;
  margin: 10px 0 0;
  z-index: 100;
}

.header-social-wrapper ul li a {
  font-size: 14px;
  padding: 0px 10px;
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding-top: 6px;
  color: rgba(0, 0, 0, 0.7);
}
.header-social-wrapper ul li a:hover {
  background: #ce8460;
  color: #fff;
}

.header-top {
  position: relative !important;
}

.navigation-dark .navbar-toggler span {
  color: #fff;
}

.navigation-2.navigation .nav-link {
  padding: 25px 8px;
}

.dark-header-socials {
  text-align: left;
}
.dark-header-socials a {
  color: #fff;
  padding: 0px 10px;
}
.dark-header-socials a:hover {
  color: #ce8460;
}

@media (max-width: 400px) {
  .header-left {
    text-align: right;
    position: absolute;
    right: 0;
    z-index: 10001;
    height: 50px;
    overflow: hidden;
    top: 13px;
  }
  .navigation .menu li {
    padding-left: 0px;
  }
  .dropdown-menu {
    display: none;
  }
  .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .navigation-2.navigation .nav-link {
    padding: 15px 8px;
  }
  .navbar-nav .nav-link {
    padding: 16px 0px;
  }
  .content {
    width: 100%;
    padding: 30px;
  }
}
@media (max-width: 575px) {
  .header-left {
    text-align: right;
    position: absolute;
    right: 20px;
    z-index: 10001;
    height: 50px;
    overflow: hidden;
    top: 13px;
  }
  .navigation .menu li {
    padding-left: 0px;
  }
  .dropdown-menu {
    display: none;
  }
  .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .navigation-2.navigation .nav-link {
    padding: 15px 8px;
  }
  .navbar-nav .nav-link {
    padding: 16px 0px;
  }
  .content {
    width: 100%;
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .header-left {
    text-align: right;
    position: absolute;
    right: 20px;
    z-index: 10001;
    height: 50px;
    overflow: hidden;
    top: 13px;
  }
  .navigation .menu li {
    padding-left: 0px;
  }
  .dropdown-menu {
    display: none;
  }
  .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .navigation-2.navigation .nav-link {
    padding: 15px 8px;
  }
  .navbar-nav .nav-link {
    padding: 16px 0px;
  }
  .content {
    width: 100%;
    padding: 30px;
  }
}
@media (max-width: 991px) {
  .header-left {
    text-align: right;
    position: absolute;
    right: 20px;
    z-index: 10001;
    height: 50px;
    overflow: hidden;
    top: 13px;
  }
  .navigation .menu li {
    padding-left: 0px;
  }
  .dropdown-menu {
    display: none;
  }
  .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
  }
  .navigation-2.navigation .nav-link {
    padding: 15px 8px;
  }
  .navbar-nav .nav-link {
    padding: 16px 0px;
  }
  .content {
    width: 100%;
    padding: 30px;
  }
}
.banner {
  position: relative;
  margin-bottom: 130px;
}

.banner-content {
  position: absolute;
  content: "";
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: 70%;
  bottom: -120px;
  background: #fff;
  padding: 50px 80px;
}

.slider-item {
  margin: 0px 10px;
}
.slider-item img {
  width: 100%;
}

.slider-item-content {
  position: relative;
}

.slider-post-content {
  position: absolute;
  bottom: 38px;
  padding: 20px;
  background: #fff;
  width: 90%;
  text-align: center;
  margin: 0 auto;
  left: 0px;
  right: 0px;
}

@media (max-width: 400px) {
  .banner-content {
    position: relative;
    width: 100%;
    padding: 50px 15px;
    bottom: 0px;
  }
}
@media (max-width: 575px) {
  .banner-content {
    position: relative;
    width: 100%;
    padding: 50px 15px;
    bottom: 0px;
  }
}
@media (max-width: 767px) {
  .banner-content {
    position: relative;
    width: 100%;
    padding: 50px 15px;
    bottom: 0px;
  }
}
.category-item {
  position: relative;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.category-item .content {
  position: absolute;
  content: "";
  left: 0px;
  right: 0px;
  background: #fff;
  padding: 25px 30px;
  bottom: -60px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.category-item .category-img {
  overflow: hidden;
  position: relative;
}
.category-item .category-img img {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.category-item .category-img:hover img {
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
}

.post-list .cat-name {
  color: #ce8460;
}
.post-list .post-view {
  position: relative;
  padding-left: 10px;
  margin-left: 10px;
}
.post-list .post-view:before {
  position: absolute;
  content: "";
  left: -10px;
  top: 13px;
  width: 10px;
  height: 1px;
  background: #000;
}

.post-article {
  padding-left: 40px;
}

.label-date .day {
  font-weight: 700;
  display: block;
}

.label-date span.month {
  font-size: 11px;
  line-height: 1em;
}

.label-date span.day {
  font-size: 20px;
  line-height: 1em;
  font-weight: 700;
  margin-bottom: 7px;
}

.label-date span {
  display: block;
}

.label-date {
  width: 64px;
  border: 2px solid #eee;
  background-color: #fff;
  padding: 12px 5px;
  text-align: center;
  z-index: 9;
  position: absolute;
  margin-top: -80px;
}

.post-content-grid {
  padding: 20px;
}
.post-content-grid p {
  font-size: 14px;
  margin-top: 15px;
}

.subscribe-home .form-control {
  background: #fff;
}

.post-quote {
  padding: 60px 40px;
  position: relative;
  z-index: 1;
}
.post-quote:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
}
.post-quote h3 {
  color: #fff;
}
.post-quote p {
  color: rgba(255, 255, 255, 0.7);
}

.post_gallery.owl-theme .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -15px;
}

.post_gallery.owl-theme .owl-nav .owl-prev {
  width: 35px;
  height: 53px;
  line-height: 15px;
  font-size: 20px;
  float: left;
  background: #ce8460;
  color: #fff;
}

.post_gallery.owl-theme .owl-nav .owl-next {
  float: right;
  background: #ce8460;
  color: #fff;
  width: 35px;
  height: 53px;
  line-height: 15px;
  font-size: 20px;
}

.play-btn {
  line-height: 45px;
  position: absolute;
  top: 33%;
  left: 0px;
  width: 120px;
  height: 120px;
  text-align: center;
  border-radius: 50%;
  right: 0px;
  margin: 0 auto;
  font-size: 37px;
  padding-top: 38px;
  background: #ce8460;
  color: #fff;
}
.play-btn:hover {
  background: #fff;
  color: #ce8460;
}

@media (max-width: 575px) {
  .category-item .content {
    position: relative;
    bottom: 0px;
  }
  .play-btn {
    width: 90px;
    height: 90px;
    font-size: 30px;
    padding-top: 24px;
  }
}
@media (max-width: 400px) {
  .category-item .content {
    position: relative;
    bottom: 0px;
  }
  .play-btn {
    width: 90px;
    height: 90px;
    font-size: 30px;
    padding-top: 24px;
  }
}
@media (max-width: 767px) {
  .category-item .content {
    position: relative;
    bottom: 0px;
  }
}
.sidebar-widget .widget-title {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.sidebar-widget .widget-title:after {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 15px;
  height: 1px;
  border-top: 1px solid;
  content: "";
  left: 50%;
  margin-left: -8px;
}
.sidebar-widget.about .about-author img {
  border-radius: 100%;
  width: 180px;
  height: 180px;
  border: 5px solid #eee;
}
.sidebar-widget.subscribe {
  border: 2px solid #000;
  padding: 30px;
}
.sidebar-widget.category li {
  padding: 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.sidebar-widget.category li span {
  display: inline-block;
  background: #ce8460;
  color: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 2px;
  line-height: 30px;
}
.sidebar-widget.tags a {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 5px 12px;
  display: inline-block;
  margin-bottom: 5px;
}

.follow-socials a {
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  color: #000;
  padding-top: 12px;
  margin-bottom: 5px;
  background: #eee;
}
.follow-socials a:hover {
  background: #ce8460;
  color: #fff;
}

.sidebar-post-item span {
  font-size: 12px;
}
.sidebar-post-item img {
  width: 100px;
}

.post-meta li {
  text-transform: uppercase;
  font-size: 12px;
  position: relative;
  padding: 0px 6px;
}

.post-socials a {
  padding: 0px 4px;
  font-size: 13px;
  color: #b8b6bb;
}
.post-socials a:hover {
  color: #ce8460;
}

.pagination ul li a {
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  padding-top: 8px;
  font-size: 14px;
}
.pagination ul li a:hover {
  background: #ce8460;
  color: #fff;
}
.pagination ul li a.active {
  background: #ce8460;
  color: #fff;
  border-color: #ce8460;
}

.post-title {
  text-transform: capitalize;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.post-grid span {
  font-size: 15px;
}

.socials a {
  padding: 0px 5px;
}

.post-tags a {
  margin-right: 15px;
}

.list-posts-share a {
  padding: 0px 10px;
}

.author-social {
  padding-right: 10px;
  font-size: 14px;
}

.post-pagination .prev-post {
  float: left;
  width: 48%;
}

.post-pagination .next-post {
  float: right;
  text-align: right;
  width: 48%;
}

.comment-area-box .media-body h4 {
  font-size: 18px;
}

.comment-form .form-control {
  background: #fff;
}

.text-underline {
  text-decoration: underline !important;
}

.instagram-post {
  position: relative;
  cursor: pointer;
}
.instagram-post:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.instagram-post .intsa-meta {
  position: absolute;
  content: "";
  left: 0px;
  top: 50%;
  right: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.instagram-post span {
  width: 40px;
  height: 40px;
  background: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  margin: 0px 5px;
  padding-top: 8px;
  font-weight: 700;
  color: #000;
}
.instagram-post:hover:before {
  opacity: 1;
  visibility: visible;
}
.instagram-post:hover .intsa-meta {
  opacity: 1;
  visibility: visible;
}

.breadcrumb-wrapper {
  margin-top: 4.5rem;
}

/* 10. CONTACT
================================================== */
.contact-form .form-control {
  background: transparent;
  border: 1px solid rgba(0, 0, 80, 0.08);
}
.contact-form label {
  color: #000;
  font-weight: 700;
  font-family: "Unna", serif;
  margin-bottom: 10px;
}

select.form-control:not([size]):not([multiple]) {
  height: auto !important;
}

.text-black {
  color: #000;
}

/*-------------------------------
footer
-------------------------------*/
.footer-section {
  background: #f8f8f8;
  background: #000;
  padding: 60px 0px 0px;
  margin-top: 150px;
  position: relative;
}

.footer-section .copyright {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  color: rgba(255, 255, 255, 0.7);
}

.copyright {
  font-size: 14px;
  padding-top: 20px;
}

.footer-socials li, .footer-socials-2 li {
  margin: 0px 25px;
}

.footer-socials li a, .footer-socials-2 li a {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}
.footer-socials li a:hover, .footer-socials-2 li a:hover {
  color: #ce8460;
}

.footer-socials li a {
  color: #fff;
  opacity: 0.8;
}

.footer-socials-2 li a {
  color: #000;
  opacity: 0.8;
}

.footer-socials li a i, .footer-socials-2 li a i {
  color: #777;
}

.footer-logo {
  color: #fff;
}

.instagram-photo-section {
  padding-bottom: 50px;
  margin-top: -250px;
}

.instagram-photo-section h4 {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 25px;
}

.instagram-photo-section img {
  border: 5px solid #fff;
}

.subscribe-footer .form-control {
  background: #fff;
}
.subscribe-footer .btn {
  padding-top: 12px;
  padding-bottom: 12px;
}

.footer-home {
  padding-left: 320px;
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  z-index: 100;
  padding-right: 30px;
}

@media (max-width: 575px) {
  .subscribe-footer .btn {
    margin-top: 10px;
  }
  .footer-home {
    padding-left: 0px;
  }
}
@media (max-width: 400px) {
  .subscribe-footer .btn {
    margin-top: 10px;
  }
  .footer-home {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .footer-home {
    padding-left: 0px;
  }
}
@media (max-width: 991px) {
  .footer-home {
    padding-left: 0px;
  }
}