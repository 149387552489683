.sidebar-widget{
	.widget-title{
		text-transform: uppercase;
		font-size: 16px;
		letter-spacing: 1px;
		position: relative;
		padding-bottom: 10px;
		margin-bottom: 30px;

		&:after{
			position: absolute;
			bottom: 0;
			overflow: hidden;
			width: 15px;
			height: 1px;
			border-top: 1px solid;
			content: '';
			left: 50%;
			margin-left: -8px;
		}

	}

	&.about{
		.about-author{
			img{
				border-radius: 100%;
				width: 180px;
				height: 180px;
				border: 5px solid #eee;
			}
		}
	}

	&.subscribe{
		border:2px solid $black;
		padding: 30px;
	}

	&.category{
		li{
			padding: 10px 0px;
			border-bottom:1px solid rgba(0,0,0,0.06);

			span{
				display: inline-block;
				background: $primary-color;
				color: $white;
				width: 30px;
				height: 30px;
				text-align: center;
				border-radius: 2px;
				line-height: 30px;
			}
		}

	}

	&.tags{
		a{
			border:1px solid rgba(0,0,0,0.06);
			padding: 5px 12px;
			display: inline-block;
			margin-bottom: 5px;
		}
	}


}

.follow-socials a{
	width: 50px;
	height:50px;
	display: inline-block;
	text-align: center;
	color: $black;
	padding-top: 12px;
	margin-bottom: 5px;
	background:#eee;

	
	&:hover{
		background: $primary-color;
		color: $white;
	}
}

.sidebar-post-item{
	span{
		font-size: 12px;
	}

	img{
		width:100px;
	}
}



.post-meta{
	li{
		text-transform: uppercase;
		font-size: 12px;
		position: relative;
		padding: 0px 6px;
	}
}



.post-socials{
	a{
		padding: 0px 4px;
		font-size: 13px;	
		color: #b8b6bb;	

		&:hover{
			color: $primary-color;
		}
	}
}

.pagination{
	ul li a{
		width: 40px;
		height:40px;
		background: #f5f5f5;
		display: inline-block;
		text-align: center;
		font-weight: 600;
		padding-top: 8px;
		font-size: 14px;

		&:hover{
			background: $primary-color;
			color: $white;
		}

		&.active{
			background: $primary-color;
			color: $white;
			border-color:$primary-color;
		}
	}
	
}

.post-title{
	text-transform: capitalize;
}


.letter-spacing-1{
	letter-spacing:1px
}

.post-grid span{
	font-size: 15px;
}

.socials{
	a{
		padding: 0px 5px;
	}
}