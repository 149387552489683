/* 10. CONTACT
================================================== */
.contact-form{
  .form-control{
    background: transparent;
    border:1px solid rgba(0,0,80,.08);
  }

  label{
    color: $black;
    font-weight: 700;
    font-family: $primary-font;
    margin-bottom: 10px;
  }
}

select.form-control:not([size]):not([multiple]) {
    height: auto!important;
}

.text-black{
  color: $black;
}

