$primary-color:#ce8460;
// $primary-color:#ce8460;
$secondary-color: #1bbb1b;
$success: #1bbb1b;
$white: #fff;
$black: #000;
$alert:#c7254e;
$border-color:#dedede;

$primary-font: 'Unna', serif;
$secondary-font:'Open Sans', sans-serif;
$extra-font:'Lora', serif;

$text-color: #292931;
$text-color-dark: #1c1c1c;
$text-color-light: #a3a3a3;

$border-radius-base: 4px;
$bg-secondary:#f0f1f4;
