/* 1.1. Typography
================================================== */

// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Lato:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Lora:400,700|Open+Sans|Unna:400,700&display=swap');
body,p{
	font-family: $secondary-font;
	line-height: 26px;
	font-size: 16px;
	text-rendering: optimizeLegibility;
	color: #666;
	font-weight: 400;
}

html{
	overflow-x: hidden;
	width: 100%;
	height: 100%;
}


h1,.h1, h2,.h2, h3, .h3, h4,.h4, h5,.h5, h6,.h6{
	color: #1c1c1c;
	font-weight: 600;
	font-family: $primary-font;
}

h1,.h1{
	font-size: 38px;
	line-height: 48px;
}

h2,.h2{
	font-size: 32px;
	line-height: 36px;
	font-weight: 700;
}

h3,.h3{
	font-size: 24px;
	font-weight: 700;
}

h4,.h4{
	font-size: 20px;
	line-height: 28px;
}

h5,.h5{
	font-size: 16px;
	line-height: 26px;
}

h6,.h6{
	font-size: 14px;
	line-height: 22px;
}

.font-extra{
	font-family: $extra-font;
}

